module.exports = [
  {
    name: "Vanzari",
    date: "February 2023",
    techStack: "React / Express / PostgreSQL / AWS",
    description:
      "A music data visualization tool that allows users to compare the sales of their favorite artists from a variety of genres. This was designed to help users follow music trends and provide the ability to compare artists careers based on their most important metrics.",
    img: "https://s3.us-west-1.amazonaws.com/vini-bjazevich.com/Website_Images/www.musicvanzari.com_.png",
    github: "",
    demo: "https://www.musicvanzari.com/",
    video: "",
  },
  {
    name: "Incredible Clothing",
    date: "April 2021",
    techStack: "React / Commerce.js / Stripe / Material-UI",
    description:
      "Created an e-commerce application that allows users to add and remove items seamlessly to the cart and make successful transactions via stripe. After a purchase is made, both the business and customer receive an itemized receipt of the purchase via email.",
    img: `https://s3-us-west-1.amazonaws.com/vini-bjazevich.com/Website_Images/IncredibleClothing2.png`,
    github: "https://github.com/ViniBjazevich/incredible-clothing-ecommerce",
    demo: "https://master.d278mjw2q11u78.amplifyapp.com/",
    video: "https://www.youtube.com/watch?v=uemQS3yv7w0",
  },
  {
    name: "Weather App",
    date: "March 2021",
    techStack: "React Native",
    description:
      "Built a mobile application that allows the user to get the weather based on their current location. Implemented routing through the use of react navigation stacks. Utilized modals to display a form that allows the user to add more locations to the home page that are rendered dynamically.",
    img: `https://s3-us-west-1.amazonaws.com/vini-bjazevich.com/Website_Images/WeatherApp.png`,
    github: "https://github.com/ViniBjazevich/weather-app",
    demo: "",
    video: "https://youtu.be/owMpUXvq1-U",
  },
  {
    name: "Slack Reactor",
    date: "Feb 2021",
    techStack:
      "React / PostgreSQL / Firebase / Node.js / Bootstrap / Socket.io",
    description:
      "Led a team of 3 engineers in creating an instant messaging application designed for members of Hack Reactor. Implemented sockets allowing messages to be sent and received in real time. Utilized Firebase for user-authentication, allowing for a custom UX for each user.",
    img: `https://s3-us-west-1.amazonaws.com/vini-bjazevich.com/Website_Images/SlackReactorLandingPage.png`,
    github: "https://github.com/hrla41-mvp/main-app",
    demo: "",
    video: "https://www.youtube.com/watch?v=K3AapnQNRWU",
  },
  // {
  //   name: "System Design",
  //   date: "Feb 2021",
  //   techStack:
  //     "React / postgreSQL / Express / Node.js / NGINX / loader.io / AWS",
  //   description:
  //     "Given a legacy code base, rebuilt the backend with postgreSQL. Seeded a database with 10,000,000 entries of mock data and stress tested the application to find bottlenecks. Used load balancing and horizontal scaling to improve the applications error rate, response time, and RPS.",
  //   img: `https://s3-us-west-1.amazonaws.com/vini-bjazevich.com/Website_Images/SystemDesign.png`,
  //   github: "https://github.com/Champion-41-SDC/SDC-Purchase-Item-Vini",
  //   demo: "",
  //   video: "",
  //
  // },
  {
    name: "Nike Clone",
    date: "Jan 2021",
    techStack: "React / MongoDB / Express / Node.js",
    description:
      "Created a replica of the Nike product page utilizing microservice architecture and merged services through a proxy server. Used agile methodology and ticketing system to effectively communicate and stay organized. Generated mock data to seed database and dynamically rendered products.",
    img: `https://s3-us-west-1.amazonaws.com/vini-bjazevich.com/Website_Images/NikeCloneCarousel.png`,
    github: "",
    demo: "",
    video: "https://youtu.be/hfo34tV3yUk",
  },
];
