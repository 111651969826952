import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Projects from "./components/Projects";
import LandingPage from "./components/LandingPage";
import Contact from "./components/Contact";
import Resume from "./components/Resume";
import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  const classes = useStyles();

  return (
    <div className={classes.appContainer}>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  appContainer: {
    height: "100%",
  },
}));
