import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Logo from "../../utils/Logo";

import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

export default function BigNav() {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        variant="elevation"
        style={{ backgroundColor: "#343844" }}
      >
        <Toolbar>
          <Button href="/">
            <Logo />
          </Button>
          <div className={classes.middle}>
            <Button href="/" className={classes.links}>
              Home
            </Button>
            <Button
              onClick={() => navigate("/projects")}
              className={classes.links}
            >
              Projects
            </Button>
            <Button
              onClick={() => navigate("/resume")}
              className={classes.links}
            >
              Resume
            </Button>
            <Button
              onClick={() => navigate("/contact")}
              className={classes.links}
            >
              Contact
            </Button>
          </div>
          <div className={classes.placeHolder} />
        </Toolbar>
      </AppBar>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#303550",
  },
  middle: {
    flexGrow: 1,
    justifyContent: "center",
    display: "flex",
  },
  links: {
    margin: "0px 20px",
    color: "#4f8bff",
    "&:hover": {
      background: "black",
      color: "#4f8bff",
    },
  },
  placeHolder: {
    width: "125px",
  },
}));
