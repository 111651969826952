import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Logo from "../../utils/Logo";
import Drawer from "../Drawer";

import { makeStyles } from "@material-ui/core/styles";

export default function SmallNav() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" variant="elevation" className={classes.appBar}>
        <Toolbar>
          <Button href="/">
            <Logo />
          </Button>
          <div className={classes.placeHolder}></div>
          <Drawer />
        </Toolbar>
      </AppBar>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#303550",
  },
  placeHolder: {
    flexGrow: 1,
    justifyContent: "center",
    display: "flex",
  },
  appBar: {
    backgroundColor: "#343844",
  },
}));
