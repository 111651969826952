import React from "react";
import Media from "react-media";
import BigNav from "./BigNav";
import SmallNav from "./SmallNav";

export default function Navbar() {
  return (
    <Media
      queries={{
        small: "(max-width: 799px)",
        medium: "(min-width: 800px)",
      }}
    >
      {(matches) => (
        <>
          {matches.small && <SmallNav />}
          {matches.medium && <BigNav />}
        </>
      )}
    </Media>
  );
}
