import React from "react";

export default function Resume() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: "64px",
      }}
    >
      <a
        style={{ margin: "1rem" }}
        href="https://s3.us-west-1.amazonaws.com/vini-bjazevich.com/Website_Images/Resume.png"
        target="_blank"
        rel="noreferrer"
      >
        <img
          style={{ width: "80vw", maxWidth: "1000px" }}
          src="https://s3.us-west-1.amazonaws.com/vini-bjazevich.com/Website_Images/Resume.png"
          alt="resume"
        />
      </a>
    </div>
  );
}
