import React from "react";

export default function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height="50px"
      viewBox="0 0 1125 449.999984"
      version="1.2"
    >
      <defs>
        <g>
          <symbol overflow="visible" id="glyph0-0">
            <path
              style={{ stroke: "none" }}
              d="M 181.042969 -166.152344 L 181.042969 -233.636719 L 13.496094 -146.605469 L 13.496094 -81.445312 L 181.042969 0.929688 L 181.042969 -67.019531 L 83.308594 -114.957031 Z M 181.042969 -166.152344 "
            />
          </symbol>
          <symbol overflow="visible" id="glyph0-1">
            <path
              style={{ stroke: "none" }}
              d="M 82.378906 -232.703125 L 130.78125 -101.460938 L 180.113281 -232.703125 L 257.835938 -232.703125 L 168.011719 0 L 93.546875 0 L 4.1875 -232.703125 Z M 82.378906 -232.703125 "
            />
          </symbol>
          <symbol overflow="visible" id="glyph0-2">
            <path
              style={{ stroke: "none" }}
              d="M 182.441406 -120.542969 C 201.523438 -129.382812 211.761719 -145.207031 211.761719 -167.082031 C 211.761719 -208.96875 184.769531 -232.703125 136.363281 -232.703125 L 15.359375 -232.703125 L 15.359375 0 L 123.800781 0 C 188.027344 0 224.328125 -23.734375 224.328125 -65.621094 C 224.328125 -92.617188 209.898438 -111.699219 182.441406 -120.542969 Z M 89.824219 -144.742188 L 89.824219 -174.527344 L 125.660156 -174.527344 C 136.832031 -173.132812 142.882812 -167.546875 142.882812 -158.703125 C 142.882812 -149.863281 136.832031 -144.742188 125.660156 -144.742188 Z M 130.316406 -58.175781 L 89.824219 -58.175781 L 89.824219 -91.21875 L 130.316406 -91.21875 C 143.347656 -91.21875 150.792969 -85.171875 150.792969 -74.464844 C 150.792969 -64.226562 143.347656 -58.175781 130.316406 -58.175781 Z M 130.316406 -58.175781 "
            />
          </symbol>
          <symbol overflow="visible" id="glyph0-3">
            <path style={{ stroke: "none" }} d="" />
          </symbol>
          <symbol overflow="visible" id="glyph0-4">
            <path
              style={{ stroke: "none" }}
              d="M 167.546875 -252.71875 L 92.152344 -252.71875 L 6.050781 20.011719 L 81.914062 20.011719 Z M 167.546875 -252.71875 "
            />
          </symbol>
          <symbol overflow="visible" id="glyph0-5">
            <path
              style={{ stroke: "none" }}
              d="M 181.042969 -146.605469 L 13.496094 -233.636719 L 13.496094 -166.152344 L 111.234375 -114.957031 L 13.496094 -67.019531 L 13.496094 0.929688 L 181.042969 -81.445312 Z M 181.042969 -146.605469 "
            />
          </symbol>
        </g>
      </defs>
      <g id="surface1">
        <g style={{ fill: "#000000", fillOpacity: 1 }}>
          <use xlinkHref="#glyph0-0" x="0.119346" y="351.007605" />
        </g>
        <g style={{ fill: "#4f8bff", fillOpacity: 1 }}>
          <use xlinkHref="#glyph0-1" x="194.64329" y="351.007605" />
        </g>
        <g style={{ fill: "#4f8bff", fillOpacity: 1 }}>
          <use xlinkHref="#glyph0-2" x="456.645638" y="351.007605" />
        </g>
        <g style={{ fill: "#4f8bff", fillOpacity: 1 }}>
          <use xlinkHref="#glyph0-3" x="693.983514" y="351.007605" />
        </g>
        <g style={{ fill: "#000000", fillOpacity: 1 }}>
          <use xlinkHref="#glyph0-4" x="756.808222" y="351.007605" />
        </g>
        <g style={{ fill: "#000000", fillOpacity: 1 }}>
          <use xlinkHref="#glyph0-5" x="930.390562" y="351.007605" />
        </g>
      </g>
    </svg>
  );
}
