import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, Typography } from "@material-ui/core";
import AWS from "aws-sdk";

export default function Contact() {
  const [from_name, setFromName] = useState("");
  const [from_email, setFromEmail] = useState("");
  const [message, setMessage] = useState("");
  const classes = useStyles();

  function sendEmail(e) {
    e.preventDefault();

    const params = {
      Destination: {
        ToAddresses: ["vinibjazevich97@gmail.com"],
      },
      Message: {
        Body: {
          Text: {
            Data: `From ${from_email}, \n\t${message}`,
          },
        },
        Subject: {
          Data: `New Message from ${from_name} via vinibjazevich.com`,
        },
      },
      Source: "vinibjazevich97@gmail.com",
    };

    const sendPromise = new AWS.SES().sendEmail(params).promise();

    sendPromise
      .then(function (data) {
        setFromName("");
        setFromEmail("");
        setMessage("");
      })
      .catch(function (err) {
        console.error(err, err.stack);
      });
  }

  useEffect(() => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      region: "us-east-1",
    });
  }, []);

  return (
    <div className={classes.container}>
      <form onSubmit={sendEmail} className={classes.form} autoComplete="off">
        <div className={classes.title}>
          <Typography
            style={{ color: "#4f8bff", alignSelf: "flex-start" }}
            variant="h3"
          >
            Contact Me
          </Typography>
          <Typography
            style={{ color: "#4f8bff", alignSelf: "flex-start" }}
            variant="h6"
          >
            Feel free to contact me about any opportunities or questions.
          </Typography>
        </div>
        <TextField
          InputLabelProps={{ className: classes.inputLabel }}
          InputProps={{ className: classes.inputText }}
          className={classes.textInput}
          value={from_name}
          required
          name="from_name"
          label="Name"
          variant="filled"
          onChange={(e) => setFromName(e.target.value)}
        />
        <TextField
          InputLabelProps={{ className: classes.inputLabel }}
          InputProps={{ className: classes.inputText }}
          className={classes.textInput}
          value={from_email}
          required
          name="from_email"
          label="Email"
          variant="filled"
          onChange={(e) => setFromEmail(e.target.value)}
        />
        <TextField
          InputLabelProps={{ className: classes.inputLabel }}
          InputProps={{ className: classes.inputText }}
          className={classes.textInput}
          value={message}
          required
          name="message"
          label="Message"
          multiline
          rows={4}
          variant="filled"
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button
          type="submit"
          size="large"
          style={{
            border: "1px solid black",
            color: "black",
            background: "#4f8bff",
            alignSelf: "flex-end",
          }}
        >
          Submit
        </Button>
      </form>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "1rem",
    marginTop: "64px",
  },
  form: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    maxWidth: "800px",
    gap: "10px",
  },
  inputText: {
    color: "lightgrey",
  },
  inputLabel: {
    color: "black",
  },
  textInput: {
    background: "#343844",
    margin: ".1rem",
    width: "100%",
    color: "white",
  },
  title: {
    alignSelf: "flex-start",
  },
}));
