import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import DescriptionIcon from "@material-ui/icons/Description";

import { useNavigate } from "react-router-dom";

export default function SwipeableTemporaryDrawer() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List style={{ padding: "0px" }}>
        <a href="/">
          <ListItem button>
            <ListItemIcon>
              <HomeIcon style={{ color: "lightgrey" }} />
            </ListItemIcon>
            <ListItemText style={{ color: "lightgrey" }} primary={"Home"} />
          </ListItem>
        </a>
        <Divider />
        <ListItem button onClick={() => navigate("/projects")}>
          <ListItemIcon>
            <LaptopMacIcon style={{ color: "lightgrey" }} />
          </ListItemIcon>
          <ListItemText style={{ color: "lightgrey" }} primary={"Projects"} />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => navigate("/resume")}>
          <ListItemIcon>
            <DescriptionIcon style={{ color: "lightgrey" }} />
          </ListItemIcon>
          <ListItemText style={{ color: "lightgrey" }} primary={"Resume"} />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => navigate("/contact")}>
          <ListItemIcon>
            <MailIcon style={{ color: "lightgrey" }} />
          </ListItemIcon>
          <ListItemText style={{ color: "lightgrey" }} primary={"Contact"} />
        </ListItem>
        <Divider />
      </List>
    </div>
  );
  let anchor = "right";

  return (
    <div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(anchor, true)}
      >
        <MenuIcon style={{ color: "black" }} />
      </IconButton>
      <SwipeableDrawer
        anchor={anchor}
        classes={{ paper: classes.paper }}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        onOpen={toggleDrawer(anchor, true)}
      >
        {list(anchor)}
      </SwipeableDrawer>
    </div>
  );
}

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#343844",
  },
});
