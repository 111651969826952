import React from "react";
import projectInfo from "../projectInfo";
import Project from "./Project";
import Media from "react-media";

export default function Projects() {
  function LargeProjects() {
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: 'repeat(auto-fill, 40%)',
          justifyContent: "space-evenly",
          marginTop: "64px",
        }}
      >
        {projectInfo.map((project, index) => (
          <Project project={project} key={index}/>
        ))}
      </div>
    );
  }

  function SmallProjects() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 1.5rem",
          marginTop: "64px",
        }}
      >
        {projectInfo.map((project, index) => (
          <Project project={project} key={index} />
        ))}
      </div>
    );
  }

  return (
    <>
      <Media
        queries={{
          small: "(max-width: 799px)",
          medium: "(min-width: 800px)",
        }}
      >
        {(matches) => (
          <>
            {matches.small && <SmallProjects />}
            {matches.medium && <LargeProjects />}
          </>
        )}
      </Media>
    </>
  );
}
