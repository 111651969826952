import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Typography,
  CardMedia,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import GitHubIcon from "@material-ui/icons/GitHub";
import PersonalVideoIcon from "@material-ui/icons/PersonalVideo";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

export default function Project({ project }) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardMedia
        image={project.img}
        title={project.name}
        className={classes.cardImage}
      />
      <CardContent>
        <div className={classes.cardNameAndDate}>
          <Typography variant="h5" gutterBottom>
            {project.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {project.date}
          </Typography>
        </div>
        <div className={classes.cardSubHeadingContainer}>
          <span className={classes.cardSubHeadingTitle}>{`Tech Stack: `}</span>
          <span className={classes.cardSubHeadingText}>
            {project.techStack}
          </span>
        </div>
        <div style={{ marginBottom: ".8rem" }}>
          <span className={classes.cardSubHeadingTitle}>{`Description: `}</span>
          <span className={classes.cardSubHeadingText}>
            {project.description}
          </span>
        </div>
        <Typography variant="body2" color="textSecondary" />
      </CardContent>
      <CardActions className={classes.buttonContainer}>
        <IconButton
          link={project.github}
          icon={<GitHubIcon />}
          label={"Github"}
        />
        <IconButton
          link={project.demo}
          icon={<PersonalVideoIcon />}
          label={"Demo"}
        />
        <IconButton
          link={project.video}
          icon={<PlayCircleFilledIcon />}
          label={"Video"}
        />
      </CardActions>
    </Card>
  );
}

const IconButton = ({ label, link, icon }) => {
  const classes = useStyles();

  return (
    <>
      {link && (
        <Button
          className={classes.buttons}
          target="_blank"
          href={link}
          variant="contained"
          startIcon={icon}
        >
          {label}
        </Button>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 300,
    width: "100%",
    margin: "1rem 0",
    background: "#343844",
    color: "lightgrey",
  },
  cardImage: {
    height: 0,
    paddingTop: "75%",
  },
  cardNameAndDate: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardSubHeadingContainer: {
    marginBottom: ".8rem",
  },
  cardSubHeadingTitle: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
  },
  cardSubHeadingText: {
    fontFamily: "Roboto",
    fontSize: "18px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  buttons: {
    background: "#4f8bff",
    "&:hover": {
      background: "#4f8bff",
    },
  },
}));
