import React from "react";
import Button from "@material-ui/core/Button";
import { TypeAnimation } from "react-type-animation";
import Particle from "./Particle";

import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";

export default function LandingPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const sequence = [
    "Hi, my name is Vini Bjazevich.",
    2000,
    "I'm a software engineer.",
    2000,
    "Check out some of my work!",
    2000,
  ];

  return (
    <div className={classes.LandingPage}>
      <Particle />
      <TypeAnimation
        className={classes.typeAnimation}
        cursor={true}
        sequence={sequence}
        repeat={Infinity}
        wrapper="div"
      />
      <Button onClick={() => navigate("/projects")} className={classes.links}>
        Projects
      </Button>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  LandingPage: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
  typeAnimation: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: "calc(16px + 2vw);",
    height: "20%",
    alignSelf: "center",
    maxWidth: "90%",
  },
  links: {
    margin: "0px 20px",
    color: "#4f8bff",
    background: "#343844",
    "&:hover": {
      background: "#303550",
      color: "#4f8bff",
    },
  },
}));
